/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {
    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // Reveal animation
        // Declared here to use in multiple name spaces
        itemReveal: function () {
            var itemQueue = [];
            var delay = 200;
            var queueTimer;

            function processItemQueue() {
                if (queueTimer) return; // We're already processing the queue
                queueTimer = window.setInterval(function () {
                    if (itemQueue.length) {
                        $(itemQueue.shift()).addClass("revealed");
                        processItemQueue();
                    } else {
                        window.clearInterval(queueTimer);
                        queueTimer = null;
                    }
                }, delay);
            }

            $(".reveal").waypoint(
                function () {
                    if (!$(this).hasClass("revealed")) {
                        itemQueue.push($(this));
                        processItemQueue();
                    }
                },
                {
                    offset: "100%",
                }
            );
        },
        stickySideNav: function () {
            var $sideNav = $(".page-side-nav");
            var extraOffset = 300;
            if ($sideNav.length > 0) {
                var $sideNavOffset = $sideNav.offset().top;
                var $sectionBelowOffset;
                var $relatedContent = $(".single__related-content");
                if ($relatedContent.length) {
                    $sectionBelowOffset = $relatedContent.offset().top;
                }
                var $advantagesBelow = $("#advantages-below");
                if ($advantagesBelow.length) {
                    $sectionBelowOffset = $advantagesBelow.offset().top;
                    extraOffset = 255;
                }

                var anchorLinks = $(".page-side-nav__link")
                    .map(function () {
                        return $(this).data("anchor");
                    })
                    .toArray();
            }

            function stickySideNav() {
                var $scrollTop = $(window).scrollTop();
                var activeAnchor = "";
                if ($sideNav.length > 0) {
                    for (var i = 0; i < anchorLinks.length; i++) {
                        var sbLink = anchorLinks[i];
                        var anchorSection = $(sbLink);
                        if (anchorSection.length > 0) {
                            var anchorPos = anchorSection.offset().top - 100;

                            if ($scrollTop >= anchorPos) {
                                activeAnchor = sbLink;
                            }
                        }
                    }
                    if (
                        $scrollTop >=
                        $sectionBelowOffset -
                            $sideNav.outerHeight() -
                            extraOffset
                    ) {
                        $sideNav.addClass("page-side-nav--absolute");
                        $sideNav.removeClass("page-side-nav--fixed");
                        $(".page-side-nav__link").removeClass(
                            "page-side-nav__link--active"
                        );
                        $("a[data-anchor='" + activeAnchor + "']").addClass(
                            "page-side-nav__link--active"
                        );
                    } else if ($scrollTop >= $sideNavOffset - 120) {
                        $sideNav.addClass("page-side-nav--fixed");
                        $sideNav.removeClass("page-side-nav--absolute");
                        $(".page-side-nav__link").removeClass(
                            "page-side-nav__link--active"
                        );
                        $("a[data-anchor='" + activeAnchor + "']").addClass(
                            "page-side-nav__link--active"
                        );
                    } else {
                        $sideNav.removeClass("page-side-nav--fixed");
                        $sideNav.removeClass("page-side-nav--absolute");
                    }
                }
            }

            $("#main-content").imagesLoaded(function () {
                stickySideNav();
            });

            $(window).scroll(function () {
                stickySideNav();
            });
        },
        // All pages
        common: {
            init: function () {
                // JavaScript to be fired on all pages

                /** GLOBAL VARIABLES */
                var body = document.querySelector("body");
                /** END GLOBAL VARIABLES */

                /** SKIP LINK NAVIGATION */
                $("#skip-to-content").click(function (event) {
                    // strip the leading hash and declare
                    // the content we're skipping to
                    var skipTo = "#" + this.href.split("#")[1];
                    // Setting 'tabindex' to -1 takes an element out of normal
                    // tab flow but allows it to be focused via javascript
                    $(skipTo)
                        .attr("tabindex", -1)
                        .on("blur focusout", function () {
                            // when focus leaves this element,
                            // remove the tabindex attribute
                            $(this).removeAttr("tabindex");
                        })
                        .focus(); // focus on the content container
                });
                /** END SKIP LINK NAVIGATION */

                $headerMenuItems = $(".header .header-menu__item");
                // var menuActive = false;
                $headerMenuItems.on("mouseenter", function () {
                    $(".header").addClass("header--active");
                    // if (menuActive) {
                    //     setTimeout(function () {
                    //         $(this).find('.header-menu__sub-menu--1').css({ display: 'block' });
                    //     }.bind(this), 300);
                    // }else {
                    //     $(this).find('.header-menu__sub-menu--1').css({ display: 'block' });
                    // }
                    // menuActive = true;
                });

                $headerMenuItems.on("mouseleave", function () {
                    $(".header").removeClass("header--active");
                    // if (menuActive) {
                    //     setTimeout(function () {
                    //         $(this).find('.header-menu__sub-menu--1').css({ display: 'none' });
                    //     }.bind(this), 300);
                    // } else {
                    //     $(this).find('.header-menu__sub-menu--1').css({ display: 'none' });
                    // }
                    // menuActive = false;
                });

                /** SHOW AND HIDE SEARCH BAR **/
                var $searchButton = $(".header__search-button"),
                    $header = $(".header"),
                    $headerNav = $(".header__navigation"),
                    $headerSearch = $(".header__search"),
                    $searchClose = $(".header__search-close");

                $searchButton.on("click", function (e) {
                    if (!$header.hasClass("header--search-open")) {
                        e.preventDefault();
                        $headerNav.fadeToggle("fast", function () {
                            $headerSearch.fadeToggle("slow");
                            $searchClose.fadeToggle("slow");
                            // Focus on input for all browsers except IE11. They have a placeholder text bug.
                            if (
                                window.ActiveXObject &&
                                !("ActiveXObject" in window)
                            ) {
                                $headerSearch
                                    .find('input[type="text"]')
                                    .focus();
                            }
                        });
                        $header.addClass("header--search-open");
                        return false;
                    } else {
                        e.preventDefault();
                        $(".header__search-form").submit();
                    }
                });
                $searchClose.on("click", function () {
                    $headerSearch.fadeToggle("fast", function () {
                        $headerNav.fadeToggle("fast");
                        $header.removeClass("header--search-open");
                    });
                    $searchClose.fadeToggle("fast");
                });

                // Social Quote Share JS

                $(".social_quote_share").on("click", function (e) {
                    // Prevent default link open
                    e.preventDefault();
                    var this_el = $(this);
                    // Get quote text from blockquote
                    var quoteText = this_el
                        .closest(".visceral_quote_share")
                        .find("blockquote")
                        .html();
                    // Remove any html tags from string
                    quoteText = quoteText.replace(/<(?:.|\n)*?>/gm, "");
                    var link;
                    if (this_el.data("social") === "twitter") {
                        link =
                            "https://twitter.com/intent/tweet?text=" +
                            encodeURIComponent(quoteText) +
                            "&url=" +
                            encodeURIComponent(window.location.href);
                    } else if (this_el.data("social") === "facebook") {
                        var appId = "1651624958476965";
                        link =
                            "https://www.facebook.com/sharer.php?displaypopup&quote=" +
                            encodeURIComponent(quoteText) +
                            "&description=" +
                            encodeURIComponent(document.title) +
                            "&u=" +
                            encodeURIComponent(window.location.href);
                    }
                    return window.open(
                        link,
                        "",
                        "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=640, height=440, top=0, left=0"
                    );
                });

                // Reveal Animation
                var imgWipes = $(".img-wipe").addClass("reveal");
                imgWipes.each(function () {
                    if ($(this).is("img")) {
                        $(this).wrap(
                            '<div class="image-wipe-wrapper reveal"></div>'
                        );
                    } else {
                        $(this).addClass("reveal");
                    }
                });

                $(".divider").addClass("reveal");
                Sage.itemReveal();

                // Masthead Animations

                var $pageTitle = $(".page-title");

                if ($pageTitle.length > 0) {
                    Splitting();

                    var splitting = document.querySelector(".splitting");
                    var $splitting = $(".splitting");
                    var splittingLineTotal = window
                        .getComputedStyle(splitting)
                        .getPropertyValue("--line-total");

                    // $splitting.addClass('lines-' + $splitting.css('--line-total'));
                    splitting.classList.add("lines-" + splittingLineTotal);

                    $splitting.wrap('<div class="page-title-container"></div>');

                    for (var i = 0; i < splittingLineTotal; i++) {
                        $splitting.prepend(
                            '<div class="title-wipe title-wipe--' +
                                (i + 1) +
                                '" style="--line-total: ' +
                                splittingLineTotal +
                                "; --line-height: " +
                                100 / splittingLineTotal +
                                ';"></div>'
                        );
                    }

                    // Variables for masthead animation
                    $titleWipes = $(".title-wipe");
                    var pageTitleWords = document.querySelectorAll(
                        ".page-title .word"
                    );
                    var pageSubHeading = document.querySelectorAll(
                        ".masthead__sub-heading"
                    );
                    var mastheadVideoModal = document.querySelectorAll(
                        ".masthead__content .video-modal"
                    );
                    var personTitle = document.querySelectorAll(
                        ".single-person__title-role"
                    );
                    var overlineLine = document.querySelectorAll(
                        ".masthead__overline .heading-line"
                    );
                    var overlineText = document.querySelectorAll(
                        ".masthead__overline .heading-line-text"
                    );
                    var logoContainer = document.querySelectorAll(
                        ".masthead__logo-container"
                    );
                    var logo = document.querySelectorAll(
                        ".masthead__logo-container img"
                    );
                    var siteSearch = document.querySelectorAll(".site-search");

                    // Global settings for masthead animation
                    var duration = 350;
                    var tl = anime.timeline({
                        easing: "cubicBezier(0.666, 0, 0.666, 0.976)",
                        // duration: 750
                    });

                    var tlLogo = anime.timeline({
                        easing: "linear",
                        // duration: 750
                    });

                    // Set the default states of each animated element
                    anime.set(pageTitleWords, { opacity: 0 });
                    if ($pageTitle.text() !== "For Media") {
                        // Animate SubHeading on all pages except the For Media page
                        anime.set(pageSubHeading, {
                            opacity: 0,
                            translateX: -5,
                        });
                    }
                    anime.set(mastheadVideoModal, {
                        opacity: 0,
                        translateX: -5,
                    });
                    anime.set(overlineLine, { translateX: "-101%" });
                    anime.set(overlineText, { opacity: 0, translateX: -5 });
                    anime.set(logoContainer, { translateY: "101%" });
                    anime.set(logo, { translateY: "-35%", translateX: "-50%" });
                    anime.set(siteSearch, { opacity: 0, translateX: -5 });

                    // Loop through each wipe element and animate in
                    $titleWipes.each(function (index) {
                        // set default state for each wipe element
                        anime.set(this, { translateX: "-101%" });
                        var delay = 0;
                        var timingOffset = "-200";

                        // Add delay for page loader if it's the first one
                        if (index === 0) {
                            delay = 1200;
                        } else {
                            // Add timing offset for all but the first one
                            timingOffset = "200";
                        }
                        tl.add(
                            {
                                targets: this,
                                translateX: "0%",
                                duration: duration,
                                delay: delay,
                            },
                            "-=" + timingOffset
                        );
                    });

                    // Page title text becomes visible
                    tl.add({
                        targets: pageTitleWords,
                        opacity: 1,
                        duration: duration,
                    });

                    // Loop through each wipe element and animate out
                    $titleWipes.each(function (index) {
                        var timingOffset = "0";

                        if (index > 0) {
                            timingOffset = "200";
                        }
                        tl.add(
                            {
                                targets: this,
                                translateX: "101%",
                                duration: duration,
                            },
                            "-=" + timingOffset
                        );
                    });

                    // If there's a sub-heading, animate it
                    if (pageSubHeading.length > 0) {
                        tl.add({
                            targets: pageSubHeading,
                            opacity: 1,
                            translateX: 0,
                            duration: duration,
                        });
                    }

                    // Animate overline in
                    tl.add(
                        {
                            targets: overlineLine,
                            translateX: 0,
                            duration: duration,
                        },
                        "-=100"
                    );

                    // If there's overline text, animate it
                    if (overlineText.length > 0) {
                        tl.add(
                            {
                                targets: overlineText,
                                opacity: 1,
                                translateX: 0,
                                duration: duration,
                            },
                            "-=150"
                        );
                    }

                    // If there's a site search, animate it
                    if (siteSearch.length > 0) {
                        tl.add({
                            targets: siteSearch,
                            opacity: 1,
                            translateX: 0,
                            duration: duration,
                        });
                    }

                    // If there's a masthead video modal, animate it
                    if (mastheadVideoModal.length > 0) {
                        tl.add({
                            targets: mastheadVideoModal,
                            opacity: 1,
                            translateX: 0,
                            duration: duration,
                        });
                    }

                    // Animate in logo container
                    tlLogo.add({
                        targets: logoContainer,
                        translateY: 0,
                        duration: duration,
                        delay: 2000,
                    });
                    // Animate in logo
                    tlLogo.add(
                        {
                            targets: logo,
                            translateY: "-50%",
                            translateX: "-50%",
                            duration: duration,
                        },
                        "-=150"
                    );
                }

                // Set up variables
                // var $bioPostsContainer = $('.bio-posts');
                var $menuItemsContent = $(".featured-menu-items__content");
                var $menuItemsImage = $(".featured-menu-items__image img");

                // IF there are bio posts, set the first bio post image to the container bg image
                if ($menuItemsContent) {
                    $menuItemsContent.on("mouseenter", function () {
                        // $bioPostsBGs.css('background-image',  'url(' + $(this).data('bg-img') + ')');
                        var $postData = $(this).data("post");

                        $menuItemsImage.removeClass("img--active");

                        $menuItemsImage.each(function () {
                            if ($(this).data("post") == $postData) {
                                $(this).addClass("img--active");
                            }
                        });
                    });

                    $menuItemsContent.on("mouseleave", function () {
                        $menuItemsImage.removeClass("img--active");
                    });
                }

                // Scroll to top
                $("#scroll-to-top").click(function () {
                    $("html, body").animate(
                        {
                            scrollTop: 0,
                        },
                        500
                    );
                    return false;
                });

                /* HEADER SCROLL EFFECT */
                // Reference: http://tympanus.net/codrops/2013/06/06/on-scroll-animated-header/
                var animatedHeader = (function () {
                    var didScroll = false,
                        scrollOffset = 0;
                    function scrollY() {
                        return (
                            window.pageYOffset ||
                            document.documentElement.scrollTop
                        );
                    }
                    function scrollPage() {
                        var sy = scrollY();
                        if (sy > scrollOffset) {
                            body.classList.add("scroll-triggered");
                        } else {
                            body.classList.remove("scroll-triggered");
                        }
                        didScroll = false;
                    }
                    function init() {
                        window.addEventListener("load", scrollPage);
                        window.addEventListener(
                            "scroll",
                            function (event) {
                                if (!didScroll) {
                                    didScroll = true;
                                    setTimeout(scrollPage, 250);
                                }
                            },
                            false
                        );
                    }
                    init();
                })();
                /* END HEADER SCROLL EFFECT */

                /** RESPONSIVE NAV OPEN/CLOSE **/
                var mobileNav = document.querySelector(".mobile-nav");
                var mobileNavButton = document.querySelector(
                    "#mobile-nav-icon"
                );
                var mobileNavClose = document.querySelector(
                    ".mobile-nav__close"
                );

                // Toggle menu icon
                if (mobileNavButton) {
                    mobileNavButton.addEventListener("click", function () {
                        mobileNav.classList.toggle("mobile-nav--active");
                    });
                }
                if (mobileNavClose) {
                    mobileNavClose.addEventListener("click", function () {
                        mobileNav.classList.toggle("mobile-nav--active");
                    });
                }

                // Close menu if page is clicked (not menu item)
                if (mobileNav) {
                    mobileNav.addEventListener("click", function (e) {
                        // Don't hide if click is on a child element (nav link)
                        if (e.target !== this) {
                            return;
                        }
                        mobileNavButton.classList.toggle("opened");
                        $("#nav-toggle").prop("checked", false);
                    });
                }
                /** END RESPONSIVE NAV OPEN/CLOSE **/

                /** MASTHEAD PROGRESSIVE IMAGES **/
                var masthead = document.querySelector(".masthead"),
                    placeholderOverlay = document.querySelector(
                        ".masthead__overlay"
                    );

                if (masthead && placeholderOverlay) {
                    // Load full size image. When loaded, fade our placeholder add it as bg to masthead
                    var img = new Image();
                    img.src = masthead.dataset.imageSrc;
                    img.onload = function () {
                        placeholderOverlay.classList.add("fade-out");
                        masthead.style.backgroundImage = "url(" + img.src + ")";
                    };
                }
                /** END MASTHEAD PROGRESSIVE IMAGES **/

                /** ANIMATED ANCHOR LINKS **/
                $('a[href*="#"]:not([href="#"])').click(function (e) {
                    if (
                        location.pathname.replace(/^\//, "") ===
                            this.pathname.replace(/^\//, "") &&
                        location.hostname === this.hostname
                    ) {
                        var target = $(this.hash);
                        var $this = this;
                        var header = $("header.header");
                        var wpAdminBar = $("#wpadminbar");
                        var fixedHeaderOffset =
                            parseInt(header.outerHeight()) -
                            parseInt(
                                header.css("padding-top").replace("px", "")
                            );
                        // If we're logged in and WP ADmin Bar exists, add it to the offset
                        if (wpAdminBar.length) {
                            fixedHeaderOffset += parseInt(
                                wpAdminBar.outerHeight()
                            );
                        }
                        target = target.length
                            ? target
                            : $("[name=" + this.hash.slice(1) + "]");
                        if (target.length) {
                            $("html,body").animate(
                                {
                                    scrollTop:
                                        target.offset().top - fixedHeaderOffset,
                                },
                                500,
                                function () {
                                    if (history.pushState) {
                                        history.pushState(
                                            null,
                                            null,
                                            $this.hash
                                        );
                                    } else {
                                        location.hash = $this.hash;
                                    }
                                }
                            );
                            e.preventDefault();
                        }
                    }
                });
                /** END ANIMATED ANCHOR LINKS **/

                // Modal for videos in masthead
                $(".video-modal").magnificPopup({
                    type: "iframe",
                    iframe: {
                        markup:
                            '<div class="mfp-iframe-scaler">' +
                            '<div class="mfp-close"></div>' +
                            '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>' +
                            '<div class="mfp-title">Some caption</div>' +
                            "</div>",
                    },
                    callbacks: {
                        markupParse: function (template, values, item) {
                            values.title = item.el.attr("title");
                        },
                    },
                });

                // Related conent slider

                // slick slide
                if ($(".related-content").length > 0) {
                    var slider = $(".related-content > .row");
                    var slides = $(".list-item-related");
                    if ($(window).outerWidth() >= 1024) {
                        if (slides.length > 3) {
                            slider.slick({
                                slide:
                                    ".related-content > .row > .list-item-related",
                                infinite: true,
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                dots: true,
                                arrows: false,
                                mobileFirst: true,
                                responsive: [
                                    {
                                        breakpoint: 768,
                                        settings: {
                                            slidesToShow: 2,
                                            slidesToScroll: 2,
                                            dots: true,
                                        },
                                    },
                                    {
                                        breakpoint: 1024,
                                        settings: {
                                            slidesToShow: 3,
                                            slidesToScroll: 3,
                                            dots: true,
                                        },
                                    },
                                ],
                            });
                        }
                    } else {
                        slider.slick({
                            slide:
                                ".related-content > .row > .list-item-related",
                            infinite: true,
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            dots: true,
                            arrows: false,
                            mobileFirst: true,
                            responsive: [
                                {
                                    breakpoint: 768,
                                    settings: {
                                        slidesToShow: 2,
                                        slidesToScroll: 2,
                                        dots: true,
                                    },
                                },
                                {
                                    breakpoint: 1024,
                                    settings: {
                                        slidesToShow: 3,
                                        slidesToScroll: 3,
                                        dots: true,
                                    },
                                },
                            ],
                        });
                    }
                }

                // Adds zeros to beginning of slick dots
                var $slickButtons = $(".slick-dots button");

                if ($slickButtons.length > 0) {
                    $slickButtons.each(function () {
                        $(this).html("0" + $(this).html());
                    });
                }

                // General Slick Slider
                // Large Images Slider
                $(".visceral-slider.careers-images").slick({
                    draggable: true,
                    infinite: false,
                    dots: false,
                    arrows: true,
                    prevArrow:
                        '<span class="careers-images__btn careers-images__btn-prev"><i class="icon-arrow-left"></i></span>',
                    nextArrow:
                        '<span class="careers-images__btn careers-images__btn-next"><i class="icon-arrow-right"></i></span>',
                    slidesToShow: 1,
                    centerMode: false,
                    autoplay: false,
                });

                // Buttons
                $(".btn, .vc_btn3").each(function () {
                    $(this).wrapInner("<span></span>");
                    if ($(this).hasClass("btn--external")) {
                        $(this).append(
                            '<div class="btn__icon-wrapper"><i class="icon-external" aria-hidden="true"></i></div>'
                        );
                    } else if ($(this).hasClass("btn--search")) {
                        $(this).append(
                            '<div class="btn__icon-wrapper"><i class="icon-search" aria-hidden="true"></i></div>'
                        );
                    } else {
                        $(this).append(
                            '<div class="btn__icon-wrapper"><i class="icon-arrow-right" aria-hidden="true"></i></div>'
                        );
                    }
                });

                $(".btn, .vc_btn3").on("mouseenter", function () {
                    if (
                        !$(this).hasClass("btn--external") &&
                        !$(this).hasClass("btn--search")
                    ) {
                        anime({
                            targets: this.querySelectorAll("i"),
                            keyframes: [
                                { marginLeft: 15 },
                                { translateX: "100%" },
                                { scaleX: 0 },
                                { translateX: "-100%" },
                                { scaleX: 1 },
                                { translateX: "0%" },
                            ],
                            duration: 600,
                            easing: "cubicBezier(0.666, 0, 0.666, 0.976)",
                            loop: false,
                        });
                    }
                });

                $(".btn, .vc_btn3").on("mouseleave", function () {
                    if (
                        !$(this).hasClass("btn--external") &&
                        !$(this).hasClass("btn--search")
                    ) {
                        anime({
                            targets: this.querySelectorAll("i"),

                            keyframes: [{ marginLeft: 0 }],
                            duration: 200,
                            easing: "cubicBezier(0.666, 0, 0.666, 0.976)",
                            loop: false,
                        });
                    }
                });
            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
                /** PRE-LOADER */
                $(window).on("load", function () {
                    // Animate loader off screen
                    $(".pre-loader").addClass("loaded");

                    // // Create custom event to list to
                    // var windowLoaded = document.createEvent('Event');

                    // // Define that the event name is 'build'.
                    // windowLoaded.initEvent('viscwindowloaded', true, true);

                    // elem.dispatchEvent(event);
                });

                /** END PRE-LOADER */
            },
        },
        // Home page
        home: {
            init: function () {
                // JavaScript to be fired on the home page

                // TODO This was commented out but it might go back in
                // var windowHeight = $(window).outerHeight();
                // var homeOrgs = $('.home-orgs-container');
                // var homeOrgsOffset = homeOrgs.offset().top;
                // // This is when top of homeOrgs is visible in window
                // var scrollTrigger = homeOrgsOffset - windowHeight;

                // function orgsScroll() {
                //     var scrollTop = $(window).scrollTop();
                //     var Horizontalscroll = scrollTop - scrollTrigger;
                //     if (scrollTop > scrollTrigger) {
                //         homeOrgs.css({'transform' : 'translateX(-' + (Horizontalscroll/3) + 'px)'});
                //     }
                // }

                // $(window).scroll(function() {
                //     orgsScroll();
                // })

                // Set up variables
                var $homePromos = $(".homepage-promo");
                var $homePromosBGs = $(".homepage-promo-bg-img");

                // IF there are home promos, set the first home promo image to the container bg image
                if ($homePromos) {
                    $homePromos.on("mouseenter", function () {
                        var $promoData = $(this).data("promo");
                        $homePromosBGs.removeClass(
                            "homepage-promo-bg-img--active"
                        );

                        $homePromosBGs.each(function () {
                            if ($(this).data("promo") == $promoData) {
                                $(this).addClass(
                                    "homepage-promo-bg-img--active"
                                );
                            }
                        });
                    });

                    $homePromos.on("mouseleave", function () {
                        $homePromosBGs.removeClass(
                            "homepage-promo-bg-img--active"
                        );
                    });

                    /** VIDEO BG */
                    // if (window.innerWidth > 768) {
                    //     $(".player").YTPlayer();
                    // }
                }
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            },
        },
        what_is_immunotherapy: {
            init: function () {
                // JavaScript to be fired on the what is immunotherapy, after the init JS
                var $timelineImage = $(".timeline-image");

                var $timelineImageContainer = $(".timeline-image").closest(
                    ".wpb_wrapper"
                );
                var $timelineImageScrollableContainer = $(
                    ".timeline-image .vc_single_image-wrapper"
                );
                var timelineActive = false;

                $timelineImageScrollableContainer.on("scroll", function () {
                    if ($timelineImageScrollableContainer.scrollLeft() > 1) {
                        if (!timelineActive) {
                            $timelineImage.addClass("timeline-image--active");
                        }
                        timelineActive = true;
                    } else {
                        $timelineImage.removeClass("timeline-image--active");
                        timelineActive = false;
                    }
                });

                // Resize timeline
                function timelineImageOffset() {
                    if ($timelineImageContainer) {
                        var windowWidth = $(window).innerWidth();
                        var $timelineImageContainerMargin;
                        if (windowWidth < 992) {
                            $timelineImageContainerMargin = 15;
                        } else if (windowWidth < 1200) {
                            $timelineImageContainerMargin = 20;
                        } else {
                            $timelineImageContainerMargin =
                                (windowWidth - 1200) / 2;
                        }
                        $timelineImageContainer.css({
                            "margin-left": $timelineImageContainerMargin,
                        });
                    }
                }

                $(window).load(timelineImageOffset);
                $(window).resize(timelineImageOffset);

                if (scroll) {
                    var windowWidth = $(window).innerWidth();
                    var $timelineImageContainerMargin;
                    if (windowWidth < 992) {
                        $timelineImageContainerMargin = 0;
                    } else if (windowWidth < 1200) {
                        $timelineImageContainerMargin = 20;
                    } else {
                        $timelineImageContainerMargin =
                            (windowWidth - 1200) / 2;
                    }
                    // For some reason, the page builder element wrapper doesn't have a width right on doc ready.
                    // So, we need to set a timeout to give it a sec to be created before getting it's width and scroll properties
                    setTimeout(function () {
                        var scroll = document.querySelector(
                            ".timeline-image .vc_single_image-wrapper"
                        ); // eslint-disable-line no-unused-vars
                        var $scroll = $(
                            ".timeline-image .vc_single_image-wrapper"
                        );
                        var maxScroll =
                            scroll.scrollWidth[0] - $scroll[0].offsetWidth;
                        //console.log($scroll[0].scrollWidth);
                        //console.log($scroll[0].offsetWidth);

                        var myImpetus = new Impetus({
                            source: scroll,
                            boundX: [-maxScroll, 0],
                            update: function (x) {
                                if (scroll) {
                                    scroll.scrollLeft = -x;
                                }
                            },
                        });
                    }, 400);
                }

                Sage.stickySideNav();
            },
            finalize: function () {},
        },
        model: {
            init: function () {
                // Variables
                var infrastructureController = new ScrollMagic.Controller();
                var modelController = new ScrollMagic.Controller();
                var $modelGraphicContainer = $(
                    ".model-graphic-container > .vc_column-inner > .wpb_wrapper"
                );
                var $modelImageEnterPICI = $(".model-image--enter-pici");
                var $modelBlockContainer = $(
                    ".model-block-container > .vc_column-inner > .wpb_wrapper"
                );
                var $modelImage1 = $(".model-image-1");
                var $modelImage2 = $(".model-image-2");
                var $modelBlock1 = $(".model-block-1");
                var $modelBlock2 = $(".model-block-2");

                if ($(window).outerWidth() > 768) {
                    if ($modelGraphicContainer && $modelImageEnterPICI) {
                        $modelGraphicContainer.prepend($modelImageEnterPICI);
                    }

                    if ($modelBlockContainer && $modelBlock2) {
                        $modelBlockContainer.append($modelBlock2);
                    }
                } else {
                    if ($modelImage1 && $modelBlock1) {
                        $modelBlock1.insertBefore($modelImage1);
                    }

                    if ($modelImage2 && $modelBlock2) {
                        $modelBlock2.insertBefore($modelImage2);
                    }
                }

                // Animation for sticky model graphic
                function stickyModelGraphicContainer() {
                    if ($(window).outerWidth() > 768) {
                        // Variables
                        var $scrollTop = $(window).scrollTop();
                        var modelBlocks = $(".model-block");
                        var modelImages = $(".model-image");

                        // If there are model blocks and iamges
                        if (modelBlocks.length > 0 && modelImages.length > 0) {
                            modelBlocks.each(function (index) {
                                var blockPos =
                                    $(this).offset().top -
                                    $(window).outerHeight() / 2 +
                                    $(this).outerHeight() / 4;
                                var blockID = $(this).attr("id");

                                if ($scrollTop >= blockPos) {
                                    modelImages.removeClass(
                                        "model-image--active"
                                    );
                                    $(".model-image--" + blockID).addClass(
                                        "model-image--active"
                                    );
                                }
                            });
                        }
                    }
                }

                function stickyInfrastructureGraphicContainer() {
                    if ($(window).outerWidth() > 768) {
                        // Variables
                        var $scrollTop = $(window).scrollTop();
                        var infrastructureBlocks = $(".infrastructure-block");
                        var infrastructureBubbles = $(".infrastructure-bubble");

                        if (
                            infrastructureBlocks.length > 0 &&
                            infrastructureBubbles.length > 0
                        ) {
                            infrastructureBlocks.each(function () {
                                var blockPos =
                                    $(this).offset().top -
                                    $(window).outerHeight() / 2;
                                var blockID = $(this).attr("id");

                                if ($scrollTop >= blockPos) {
                                    infrastructureBubbles.removeClass(
                                        "infrastructure-bubble--active"
                                    );
                                    $("#" + blockID + "-bubble").addClass(
                                        "infrastructure-bubble--active"
                                    );
                                }
                            });
                        }
                    }
                }

                $(window).load(function () {
                    stickyModelGraphicContainer();
                    stickyInfrastructureGraphicContainer();
                });
                $(window).scroll(function () {
                    stickyModelGraphicContainer();
                    stickyInfrastructureGraphicContainer();
                });

                if ($(window).outerWidth() > 768) {
                    // build scenes
                    new ScrollMagic.Scene({
                        triggerElement: ".model-section",
                        triggerHook: "onLeave",
                        offset: 0,
                        duration: $(".model-block-1").outerHeight(),
                    })
                        .setPin(".model-graphic-container .vc_column-inner")
                        // .setTween(modelAnimation)
                        .addTo(modelController);

                    new ScrollMagic.Scene({
                        triggerElement: ".infrastructure-section",
                        triggerHook: "onLeave",
                        offset: -100,
                        duration:
                            $(".infrastructure-section").outerHeight() -
                            $(".infrastructure-block-1").outerHeight(),
                    })
                        .setPin(".infrastructure-graphic-container")
                        .addTo(infrastructureController);
                }
            },
            finalize: function () {
                // JavaScript to be fired on the model page, after the init JS
            },
        },
        // Accomplishments
        accomplishments: {
            init: function () {
                // JavaScript to be fired on the Accomplishments
                // Sticky Filters on scroll
                var $timeline = $(".timeline-container");
                var $timelineLine = $(".timeline-line-container");
                var $masthead = $(".masthead");
                var $timelineOffset =
                    $timeline.offset().top + $timeline.outerHeight();

                function stickyTimeline() {
                    var $scrollTop = $(window).scrollTop();
                    if (
                        $scrollTop + $(window).outerHeight() >=
                        $timelineOffset + 150
                    ) {
                        $timelineLine.addClass(
                            "timeline-line-container--absolute"
                        );
                    } else {
                        $timelineLine.removeClass(
                            "timeline-line-container--absolute"
                        );
                    }
                }

                $(window).load(function () {
                    stickyTimeline();
                });
                $(window).scroll(function () {
                    stickyTimeline();
                });

                var waypoints = $(".list-item-timeline").waypoint({
                    handler: function (direction) {
                        if (direction === "down") {
                            $(this).addClass("list-item-timeline--revealed");
                        } else {
                            $(this).removeClass("list-item-timeline--revealed");
                        }
                    },
                    offset: "55%",
                });
            },
            finalize: function () {
                // JavaScript to be fired on the Accomplishments, after the init JS
            },
        },
        // Published Research
        published_research: {
            init: function () {
                // JavaScript to be fired on the Published Research
            },
            finalize: function () {
                // JavaScript to be fired on the Published Research, after the init JS
            },
        },
        single_post: {
            init: function () {
                // Resize post content
                var postContent = $(".single-post__content-main");
                function postContentOffset() {
                    if (postContent) {
                        var windowWidth = $(window).innerWidth();
                        var postContentMargin;
                        if (windowWidth < 992) {
                            postContentMargin = 0;
                        } else if (windowWidth < 1200) {
                            postContentMargin = 20;
                        } else {
                            postContentMargin = (windowWidth - 1200) / 2;
                        }
                        postContent.css({ "padding-left": postContentMargin });
                    }
                }

                $(window).load(postContentOffset);
                $(window).resize(postContentOffset);

                var $socialShareLinks = $(".social-share-links a");

                $socialShareLinks.on("click", function (e) {
                    e.preventDefault();

                    window.open(
                        this.href,
                        "",
                        "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=640, height=440, top=0, left=0"
                    );
                });
            },
        },
        single_person: {
            init: function () {
                // Toggle for Person Info
                $(".person-content__header").on("click", function () {
                    $(this)
                        .parent(".person-content")
                        .children(".person-content__main")
                        .slideToggle();
                    $(this).toggleClass("person-content__header--active");
                });
            },
        },
        single_research_project: {
            init: function () {
                Sage.stickySideNav();
            },
        },
        single_clinical_trial: {
            init: function () {
                Sage.stickySideNav();
            },
        },
        single_research_focus: {
            init: function () {
                Sage.stickySideNav();
            },
        },

        // Search and Filter pages
        page_template_template_search_filter: {
            init: function () {
                var searchResultsHeight = $(
                    ".search-filter-results"
                ).outerHeight();
                var windowHeight = $(window).outerHeight();
                // Add and modify existing layout and classes of search and filter form
                $(".searchandfilter > ul").addClass(
                    "visc-searchandfilter-filters"
                );
                $(".visc-searchandfilter-filters").prepend(
                    '<p class="visc-searchandfilter-filters__heading"><i class="icon-filter" aria-hidden="true"></i>Filters</p>'
                );

                $(".searchandfilter").prepend(
                    '<ul class="visc-searchandfilter-search"></ul>'
                );
                $(".sf-field-search").prependTo(".visc-searchandfilter-search");

                // Add + icon
                $(".searchandfilter li > h4").prepend(
                    '<i class="icon-expand" aria-hidden="true"></i>'
                );

                // On icon click, toggle options
                $searchToggle = $(".searchandfilter li > h4");
                $searchToggle.on("click", function () {
                    $(this).closest("li").find("ul").slideToggle();
                    $(this)
                        .find("i")
                        .toggleClass("icon-expand")
                        .toggleClass("icon-collapse");
                });

                // Add icon to reset filter
                $searchReset = $(".search-filter-reset");

                if ($searchReset.length > 0) {
                    $searchReset.prepend(
                        '<i class="icon-reset" aria-hidden="true"></i>'
                    );
                }

                // Toggle More content
                $publicationMore = $(".list-item-publication__more");
                $publicationMore.on("click", function () {
                    $(this)
                        .find("i")
                        .toggleClass("icon-expand")
                        .toggleClass("icon-collapse");
                    $(this).toggleClass("list-item-publication__more--active");
                    $(this)
                        .closest(".list-item-publication__main")
                        .find(".list-item-publication__content")
                        .slideToggle();
                });

                $inventionMore = $(".list-item-invention__more");
                $inventionMore.on("click", function () {
                    $(this)
                        .find("i")
                        .toggleClass("icon-expand")
                        .toggleClass("icon-collapse");
                    $(this).toggleClass("list-item-invention__more--active");
                    $(this)
                        .closest(".list-item-invention__header")
                        .next(".list-item-invention__main")
                        .slideToggle();
                });

                $(document).on(
                    "sf:ajaxfinish",
                    ".searchandfilter",
                    function () {
                        searchResultsHeight = $(
                            ".search-filter-results"
                        ).outerHeight();
                        $filters.removeClass(
                            "visc-searchandfilter-filters--absolute"
                        );

                        // Reattach event listener to newly AJAXed in content
                        $publicationMore = $(".list-item-publication__more");
                        $publicationMore.on("click", function () {
                            $(this)
                                .closest(".list-item-publication__main")
                                .find(".list-item-publication__content")
                                .slideToggle();
                        });

                        $inventionMore = $(".list-item-invention__more");
                        $inventionMore.on("click", function () {
                            $(this).toggleClass(
                                "list-item-invention__more--active"
                            );
                            $(this)
                                .closest(".list-item-invention__header")
                                .next(".list-item-invention__main")
                                .slideToggle();
                        });
                        // Buttons
                        $(".btn, .vc_btn3").each(function () {
                            $(this).wrapInner("<span></span>");
                            if ($(this).hasClass("btn--external")) {
                                $(this).append(
                                    '<div class="btn__icon-wrapper"><i class="icon-external" aria-hidden="true"></i></div>'
                                );
                            } else if ($(this).hasClass("btn--search")) {
                                $(this).prepend(
                                    '<div class="btn__icon-wrapper"><i class="icon-search" aria-hidden="true"></i></div>'
                                );
                            } else {
                                $(this).append(
                                    '<div class="btn__icon-wrapper"><i class="icon-arrow-right" aria-hidden="true"></i></div>'
                                );
                            }
                        });
                    }
                );

                // Sticky Filters on scroll
                var $filters = $(".visc-searchandfilter-filters");
                var $masthead = $(".masthead");
                var $content = $(".content");
                $filtersOffset = $filters.offset().top;
                function stickyFilters() {
                    if ($(window).outerWidth() >= 992) {
                        var $scrollTop = $(window).scrollTop();

                        if (
                            $scrollTop >=
                                $masthead.outerHeight(true) +
                                    $content.outerHeight(true) -
                                    $(window).height() &&
                            searchResultsHeight > windowHeight
                        ) {
                            $filters.removeClass(
                                "visc-searchandfilter-filters--fixed"
                            );
                            $filters.addClass(
                                "visc-searchandfilter-filters--absolute"
                            );
                        } else if (
                            $scrollTop >= $filtersOffset - 120 &&
                            searchResultsHeight > windowHeight
                        ) {
                            $filters.addClass(
                                "visc-searchandfilter-filters--fixed"
                            );
                            $filters.removeClass(
                                "visc-searchandfilter-filters--absolute"
                            );
                        } else {
                            $filters.removeClass(
                                "visc-searchandfilter-filters--fixed"
                            );
                        }
                    }
                }

                function filterMargin() {
                    var $windowWidth = $(window).outerWidth();

                    if ($windowWidth >= 992) {
                        var margin;
                        if (
                            $(".visc-searchandfilter-filters").hasClass(
                                "visc-searchandfilter-filters--absolute"
                            )
                        ) {
                            margin = 15;
                        } else {
                            if ($windowWidth >= 1300) {
                                margin = ($windowWidth - 1300) / 2 + 15;
                            } else {
                                margin = 15;
                            }
                        }
                        $(".visc-searchandfilter-filters").css({
                            left: margin,
                        });
                    }
                }

                $(window).load(function () {
                    stickyFilters();
                    filterMargin();
                });

                $(window).scroll(function () {
                    stickyFilters();
                    filterMargin();
                });

                $(window).resize(function () {
                    filterMargin();
                    windowHeight = $(window).outerHeight();
                });
            },
        },
        the_latest: {
            init: function () {
                // JavaScript to be fired on the The Latest page

                /* AJAX LOAD AND FILTER */
                // Global Variables
                var $loadMore = $("#load-more button");
                var $postLoader = $(".post-loader");
                var totalPosts = $("#total-posts-count").data("total-posts");
                var $container = $(".posts-list .row");
                var $postsList = $(".posts-list");
                var queryVars = visceral_vars.query_vars;
                var offset = visceral_vars.query_vars.posts_per_page;
                var i = 1;
                var categoryFilter = "";

                // Checks inputs of a given filter set and adds or removes values to corresponding array
                function checkFilter() {
                    var optionSelected = $("#cat-filter").find(
                        "option:selected"
                    );
                    categoryFilter = optionSelected.val();
                }

                function ajaxFilterPosts() {
                    // Reset iterator
                    i = 1;
                    // Reset offset
                    offset = 0;

                    $.ajax({
                        // This is admin-ajax.php
                        url: visceral.ajaxurl,
                        type: "post",
                        data: {
                            // This is a php function that runs new query
                            //  All of this data is sent to the php function so it can use the variables
                            action: "ajax_load_posts",
                            security: visceral_vars.security,
                            query_vars: visceral_vars.query_vars,
                            offset: offset,
                            cat_filter: categoryFilter,
                        },
                        beforeSend: function () {
                            $postsList.addClass("loading");
                            $postLoader.addClass("loading");
                        },
                        complete: function () {
                            $postsList.removeClass("loading");
                            $postLoader.removeClass("loading");
                            // Reveal Animation
                            Sage.itemReveal();
                        },
                        success: function (jsonData) {
                            //  This is the response back from the php function
                            var data = JSON.parse(jsonData);

                            // Update the total posts data from the new query
                            $("#total-posts-count").attr(
                                "data-total-posts",
                                data.total_posts
                            );
                            // Remove all posts and add in what was retrieved from new query
                            $container.empty();

                            $container.prepend(data.posts);
                            // Set totalPosts variable to new total posts data
                            totalPosts = data.total_posts;
                            var $containerChildren = $container.children()
                                .length;
                            // // Check to see if we need to show or hide the load more button (if there are more posts)
                            if (
                                $containerChildren < totalPosts &&
                                !$loadMore.is(":visible")
                            ) {
                                $loadMore.show();
                            } else if ($containerChildren >= totalPosts) {
                                $loadMore.hide();
                            }
                            // Adds query string to url for link sharing
                            var queryString = "";
                            if (categoryFilter) {
                                queryString += "cat-filter=" + categoryFilter;
                            }
                            // Add query string to url
                            var pageUrl = "?" + queryString;
                            window.history.pushState("", "", pageUrl);
                        },
                        error: function (
                            MLHttpRequest,
                            textStatus,
                            errorThrown
                        ) {
                            alert(errorThrown);
                        },
                    });
                }

                // Function for load more
                function ajaxLoadPosts() {
                    // Increase the offset by posts_per_page for load more function
                    offset = visceral_vars.query_vars.posts_per_page * i;
                    $.ajax({
                        url: visceral.ajaxurl,
                        type: "post",
                        data: {
                            action: "ajax_load_posts",
                            security: visceral_vars.security,
                            query_vars: queryVars,
                            offset: offset,
                            cat_filter: categoryFilter,
                        },
                        beforeSend: function () {},
                        complete: function () {
                            $postsList.removeClass("loading");
                            $postLoader.removeClass("loading");
                            // Reveal Animation
                            Sage.itemReveal();
                        },
                        success: function (jsonData) {
                            var data = JSON.parse(jsonData);
                            $container.append(data.posts);

                            // Get Number of posts in container
                            var $containerChildren = $container.children()
                                .length;
                            // Check to see if we have the total number of posts we can get. If we do, hide the load more button
                            if ($containerChildren >= totalPosts) {
                                $loadMore.hide();
                                // If not, make sure the load more is visible
                            } else {
                                if (!$loadMore.is(":visible")) {
                                    $loadMore.show();
                                }
                            }
                            i++;
                        },
                    });
                }
                var allowInfinite = true;
                function infiniteLoad() {
                    var $containerChildren = $(".posts-list .row").children()
                        .length;
                    var totalPosts = $("#total-posts-count").attr(
                        "data-total-posts"
                    );
                    var $scrollTop = $(window).scrollTop();
                    var $windowHeight = $(window).outerHeight();
                    var $postsList = $(".posts-list");
                    var $postsListOffset = $postsList.offset().top;

                    console.log("container children: " + $containerChildren);
                    console.log(
                        "total posts: " +
                            $("#total-posts-count").attr("data-total-posts")
                    );

                    if (
                        $scrollTop + $windowHeight >
                        $postsListOffset + $postsList.outerHeight()
                    ) {
                        if (!allowInfinite) {
                            return;
                        }
                        if ($containerChildren < totalPosts) {
                            $postsList.addClass("loading");
                            $postLoader.addClass("loading");
                            checkFilter();
                            ajaxLoadPosts();
                        }
                        // Set loading flag
                        allowInfinite = false;

                        // Allow infinite load after a second
                        setTimeout(function () {
                            allowInfinite = true;
                        }, 1000);
                    }
                }

                $(window).scroll(function () {
                    infiniteLoad();
                });

                $loadMore.on("click", function (e) {
                    e.preventDefault();
                    // Run check filter on category inputs
                    checkFilter();
                    ajaxLoadPosts();
                });

                $("#cat-filter").css({ width: 110 + "px" });

                // Run AJAX request when submit button clicked
                $("#cat-filter").on("change", function (e) {
                    // Don't actually submit form
                    e.preventDefault();
                    // Run check filter on category input
                    checkFilter();
                    // Change filter width on filter change
                    changeFilterWidth();

                    // Run AJAX request
                    ajaxFilterPosts();
                });

                // Dynamically set the width of the filter input
                function changeFilterWidth() {
                    var optionText = $("#cat-filter")
                        .find("option:selected")
                        .text();
                    var $fakeSelect = $("<select/>").append(
                        $("<option/>").text(optionText)
                    );
                    $("#cat-filter").after($fakeSelect);
                    $("#cat-filter").width($fakeSelect.width());
                    $fakeSelect.remove();
                }

                // Set filter width on page load
                changeFilterWidth();
            },
        },
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = funcname === undefined ? "init" : funcname;
            fire = func !== "";
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === "function";

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire("common");

            // Fire page-specific init JS, and then finalize JS
            $.each(
                document.body.className.replace(/-/g, "_").split(/\s+/),
                function (i, classnm) {
                    UTIL.fire(classnm);
                    UTIL.fire(classnm, "finalize");
                }
            );

            // Fire common finalize JS
            UTIL.fire("common", "finalize");
        },
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
